.awards-section .col-lg-3 {
  margin-bottom: 30px;
}
.white-bg {
  background: #ffffff;
}
.appIcon {
  padding: 0px;
  margin: 0px 0px 30px;
}
.appIcon li {
  display: inline-block;
  vertical-align: top;
}
.letter-space {
  letter-spacing: 1px;
  margin-left: 10px;
}
.footer-bottom {
  background: #000000;
  font-size: 15px;
  color: #575757;
  font-weight: 400;
}
.bottom-footer {
  padding: 0px;
  margin: 0px;
  text-align: right;
}
.bottom-footer li {
  display: inline-block;
  vertical-align: top;
}
.bottom-footer li a {
  display: block;
  margin: 5px 0px 5px 10px;
  padding: 0px 0px 0px 10px;
  border-left: 1px solid #cdcccc;
  line-height: 14px;
}
.bottom-footer li:first-child a {
  border-left: none;
}

.mobile-menu .cc-color {
  color: #ed3237 !important;
  font-size: 20px;
  font-weight: 500;
  border-top: 1px solid #242527;
  margin-top: 20px;
}

.main-heading h2 {
  position: relative;
  color: #373435;
  font-size: 25px !important;
  font-weight: 400;
}
.main-heading h2:before {
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  width: 45px;
  height: 3px;
  background: #ed3237;
  content: "";
  margin: 0px auto;
}

.main-heading h1 {
  position: relative;
  color: #373435;
  font-size: 25px !important;
  font-weight: 400;
}
.main-heading h1:before {
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  width: 45px;
  height: 3px;
  background: #ed3237;
  content: "";
  margin: 0px auto;
}

.innerpage-sk .main-heading h2.padding-arival:before {
  right: 0px !important;
}
.main-heading p {
  font-size: 16px;
  color: #636363;
  line-height: 24px;
  margin-top: 30px;
}
.para-padd p {
  padding: 0px 260px;
}

.home-about {
  background: url(../image/aboutbanner.jpg) no-repeat;
  text-align: left;
  color: #fff;
  margin-top: 20px;
  padding: 50px 0px;
  background-size: cover;
  background-position: center bottom;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
}
.shape {
  position: absolute;
  bottom: 220px;
  left: -130px;
  width: 150%;
  height: 150%;
  background: #000000c9;
  -webkit-transform: rotate(160deg);
  transform: rotate(160deg);
}
.home-about .main-heading h2 {
  color: #ffffff;
}
.home-about .main-heading h2:before {
  background: #ffffff;
  margin: 0px;
}
.home-about .main-heading p {
  color: #ffffff;
}
.home-about .main-heading a {
  color: #fff;
  display: inline-block;
  border: 1px solid #fff;
  padding: 5px 15px;
  border-radius: 3px;
  cursor: pointer;
}
.home-about img {
  border: 8px solid #fff;
  height: 100%;
}

.new-arrivals {
  background: #f2fcfb;
  padding: 50px 0px;
}
.new-arrivals .creative-grid {
  padding-top: 40px;
}

.germfree {
  background: #373737;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  height: 100%;
  display: table;
}
.germfree a {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}
.germfree b {
  /* text-transform: uppercase;font-style: italic; */
  font-weight: 700 !important;
}
.germfree:hover {
  background: #ed3237;
}

.locateus {
  background: url(../image/locateus-bg.jpg) no-repeat;
  background-size: cover;
  padding: 40px 20px;
}
.locateus .main-heading h2 {
  color: #ffffff;
  margin-bottom: 40px;
}
.locateus .main-heading h2:before {
  background: #ffffff;
  margin: 0px auto;
}
.locateus form {
  margin: 0px;
}
.locateus label {
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  display: block;
}
.locateus select.form-control:not([size]):not([multiple]) {
  height: 5rem;
  border-radius: 4px;
  border: none;
  width: 100%;
  margin: 0;
  font-size: 16px;
  color: #333333;
}
.locateus button {
  width: 100%;
  border-radius: 4px;
  border: none;
  background: #ed3237;
}

.knowledgesupport img {
  display: block;
  text-align: center;
  margin: 0px auto;
}
.knowledgesupport .blackstrip {
}
.knowledgesupport {
  margin-top: 40px;
}
.knowledgesupport-box {
  position: relative;
}
.knowledgesupport .blackstrip {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background: #000000cf;
  padding: 10px 20px;
}
.knowledgesupport .blackstrip h2 {
  color: #fff;
  margin: 0px;
  padding: 12px 0px;
  font-size: 25px;
  font-weight: 400;
}
.knowledgesupport .blackstrip a {
  font-size: 15px;
  display: block;
  color: #fff;
  /* font-size: 1.5rem; */
}
.knowledgesupport .blackstrip a.explorebutton {
  position: relative;
  top: 20px;
  text-align: right !important;
}

.brandsection {
  padding: 40px 0px;
  background: #fff;
}
.brandsection .owl-carousel .owl-item {
  /* background: transparent !important; */
}
.brandsection .owl-carousel.show-nav-hover .owl-nav {
  opacity: 1;
  position: relative;
  width: 100%;
}
.brandsection .owl-carousel .owl-nav .owl-prev {
  left: -2vw;
  position: absolute;
  top: -60px;
  font-size: 55px;
  display: block;
  margin: 0;
}
.brandsection .owl-carousel .owl-nav .owl-prev:hover {
  background: none;
  color: #ed3237;
}
.brandsection .owl-carousel .owl-nav .owl-next {
  right: -2vw;
  position: absolute;
  top: -60px;
  font-size: 55px;
  display: block;
  margin: 0;
}
.brandsection .owl-carousel .owl-nav .owl-next:hover {
  background: none;
  color: #ed3237;
}
.sku-code-font {
  font-size: 15px;
}

@media only screen and (max-width: 480px) {
  .wishlist-mobile-view {
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 40%);
  }
  .btn-remove {
    right: -97% !important;
    top: -3% !important;
    font-size: 12px !important;
  }
  .wishlist-code-sku {
    font-size: 8px;
  }
  .product-wishlist-mobile {
    width: 50px;
  }
  .sku_code_detail {
    font-size: 13px;
  }
  .brandsection .owl-carousel .owl-nav .owl-prev {
    left: -2vw;
    position: absolute;
    top: -55px;
  }
  .product-details .button-box {
    margin-top: 5px;
  }
  .brandsection .owl-carousel .owl-nav .owl-next {
    right: -2vw;
    position: absolute;
    top: -55px;
  }
}

.brandsection h2:before {
  margin: 0px;
}

.loyalty {
  padding: 50px 0px;
}
.loyalty h2:before {
  margin: 0px;
}
.loyalty ul {
  padding: 0px;
  margin: 40px 0px 0px;
}
.loyalty ul li {
  display: inline-block;
  vertical-align: top;
  width: 42%;
  margin-right: 32px;
  /* border: 1px solid #cdcccc; */
}
.loyalty a.downloadapp {
  margin: 40px 0px 0px;
  width: 86%; /* display: block; */
}
.category-content {
  padding: 0px;
}

/**************************************/

/*Category page*/
.footer-links {
  color: #fff !important;
  padding: 0px 15px;
}

.banner {
  position: relative;
}
.breadscrumb {
  position: absolute;
  right: 100px;
  bottom: 80px;
  background: #000;
  color: #fff;
}
ul.bradscrumb-nav {
  list-style: none;
  margin: 0;
}
ul.bradscrumb-nav li {
  display: inline-block;
  padding: 10px 0 10px 10px;
} /* [Media Query] */
ul.bradscrumb-nav li a {
  color: #cbcaca;
}
ul.bradscrumb-nav li a.active {
  color: #ffffff;
}
ul.bradscrumb-nav li:after {
  content: "|";
  margin-left: 10px;
}
ul.bradscrumb-nav li:last-child:after {
  content: "";
  margin-left: 10px;
}
.listing {
  padding: 0px !important;
}

.bodybg {
  background: #f5f5f5;
}
.page-heading {
}
.page-heading::after {
  position: absolute;
  content: "";
  width: 20%;
  width: 20%;
  top: 67px;
  border-bottom: 4px solid #ed3237;
  left: 1%;
}
.product-box {
  background: #fff;
  height: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  position: relative;
}
.product-box:hover {
  border-color: #ed3237;
}
/* .product-box span .fa{position: absolute; top: 15px; right: 15px; padding: 6px 13px; text-align: center; font-size: 20px; color: #646363; background: #f5f5f5; border-radius: 50%;} */
/* .product-box:hover span .fa{background:#ed3237;color: #ffffff;} */
.product-img {
  text-align: center;
}
.product-img img {
  display: inline-block;
  max-height: 269px;
  padding-top: 30px;
}
.cat-product-title {
  color: #646363 !important;
  padding: 10px !important;
  background: none !important;
}
.product-box:hover .cat-product-title {
  background: #ed3237 !important;
  color: #fff !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cat-product-box:hover {
  border: 1px solid #ed3237;
  text-decoration: none;
}

ul.availablecolor {
  margin: 0;
  color: #636363;
}
ul.availablecolor li:first-child {
  border: 0px;
}
ul.availablecolor li {
  display: inline-block;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid #cdcccc;
}
ul.availablecolor li div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.button-box {
  border: 1px solid #dddddd;
  padding: 3px 8px;
  background: #fff;
}
.product-default:hover {
  border: 1px solid #ed3237;
}
.button-box:hover {
  background: #ed3237;
  color: #fff;
}

.listing .main-heading h2:before {
  margin: 0;
}
.sidebar-shop .main-heading h2:before {
  margin: 0;
}
/*********************** END ***********************/

/***************** Categories Listing *****************/
.sidebar-wrapper {
  background: #ffffff;
  position: relative;
  margin-top: 0px !important;
}
.sidebar-wrapper:before {
  position: absolute;
  top: 0px;
  left: 0px;
  background: url(../image/siderbar-top-zigzag.png) no-repeat;
  content: "";
  width: 100%;
  height: 9px;
  z-index: 9;
}
.sidebar-wrapper:after {
  position: absolute;
  bottom: -1px;
  left: 0px;
  background: url(../image/siderbar-bottom-zigzag.png) no-repeat;
  content: "";
  width: 100%;
  height: 9px;
  z-index: 9;
}
.sidebar-shop .pin-wrapper .widget {
  border: none;
}
.sidebar-shop input[type="checkbox"],
.sidebar-shop input[type="radio"] {
  vertical-align: middle;
  position: absolute;
  top: 4px;
  left: 0;
}
.sidebar-shop .custom-control {
  margin: 0px;
}
.sidebar-shop .cat-list li a input[type="radio"] {
}
.listingpage h2:before {
  margin: 0px;
}

.categorylisting .product-default {
  border: 1px solid #e7e7e7;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 4px 3px #e5e5e5;
}
.categorylisting .product-details {
  padding: 10px !important;
  background: #f5f5f5;
}
.categorylisting .inner-icon figure .btn-icon-group {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
}
.categorylisting .inner-icon figure .product-listing-newlabel {
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  /* width: 72px; */
  display: flex;
}
.categorylisting .inner-icon figure .product-listing-newlabel span {
  display: flex;
  justify-content: center;
  align-items: center;
}

.categorylisting .inner-icon figure .product-detail-germfree {
  position: absolute;
  bottom: 0.2rem;
  right: 0.2rem;
  /* width: 72px; */
  display: flex;
}

.categorylisting .inner-icon figure .product-detail-germfree span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.categorylisting .product-default:hover {
  border: 1px solid #ed3237;
}
.categorylisting .product-default:hover .button-box {
  background: #ed3237;
  color: #fff;
  border-color: #ed3237;
}
.categorylisting .product-default .btn-icon {
  border-radius: 4px !important;
  background-color: #e13b3f;
  border-color: #e13b3f;
  color: #fff;
}
.categorylisting .product-default .product-title a {
  color: #373435;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.extraspace {
  height: 100px !important;
}
/*********************** END ***********************/

/***************** Product Detail *****************/
.product-detail-gallery .owl-carousel .owl-stage {
  width: 100% !important;
}
.state-choose strong {
  display: block;
}
.product-detail-gallery .owl-carousel.show-nav-hover:hover .owl-nav {
  opacity: 0;
}
.product-detail-gallery .prod-thumbnail {
  background: #fff;
}
.product-detail-gallery .prod-thumbnail .owl-dot.active img,
.prod-thumbnail img:hover {
  border: 1px solid #d9d7d7;
}
.product-detail-gallery .prod-thumbnail .owl-item {
  padding: 8px;
  width: 100px !important;
}

.product-detail-gallery .prod-full-screen {
  opacity: 1;
  background: #f5f5f5;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
}
.product-detail-gallery .prod-full-screen i {
  color: #7c7777;
  font-size: 18px;
}
.product-detail-gallery .product-label.label-hot {
  background: #ffffff;
}
.product-single-gallery .label-group {
  top: inherit;
  bottom: 0px;
}

.product-details {
  padding: 0px 20px;
}
.product-details .main-heading h2:before {
  margin: 0px;
}
.product-details ul.availablecolor {
  margin-top: 20px;
}

.product-details .product-nav {
}
.product-details .product-nav li {
  list-style: none;
  vertical-align: top;
  display: inline-block;
  font-size: 25px;
  padding-left: 5px;
}
.product-details .product-nav li .icon-heart {
  color: #ed3237;
}
.product-details .product-nav li .fa-print {
  color: #93784e;
}
.product-details .product-nav li .fa-share-alt {
  color: #0f9e13;
}
.product-details h3 {
  color: #373435;
  font-size: 17px !important;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  text-transform: capitalize;
}

.product-details .single-info-list {
  color: #373435;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  border-bottom: 1px solid#cdcccc;
  padding-bottom: 10px;
}
.product-details .single-info-list li {
  margin-bottom: 0px;
}

.product-details .price_element{
  color: #373435;
  font-size: 16px;
  font-weight: 500;
}

.product-details .product-desc {
  font-size: 16px;
  color: #636363;
  line-height: normal;
  border-bottom: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  padding-top: 10px;
}
/* .product-desc p:first-child{display: none;} */

.product-details .product-desc-content ul {
  margin: 12px 0px 0px;
  padding: 0px;
}
.product-details .product-desc-content li {
  margin-top: 6px;
  padding-left: 25px;
}
.product-details .product-desc-content li:before {
  left: 0px;
  margin: 0px;
}

.product-details .product-action h3 {
  float: left;
  margin-right: 15px;
}
.product-details .product-action select {
  height: 46px;
  background: #f5f5f5;
  border-color: #dae2e6;
}
.product-details .product-price {
  color: #ed3237;
  font-size: 25px !important;
  font-weight: 400;
  padding-left: 10px;
}

.product-details .product-action {
  border-top: none;
  padding-top: 0px;
  display: flex;
}
.product-details .button-box {
  display: inline-block;
  border: 1px solid #dddddd;
  padding: 6px 12px;
  color: #373435;
}
.product-details .button-box .fa-map-marker-alt {
  color: #2e67da;
}
.product-details .button-box .fa-info-circle {
  color: #00b286;
}
.product-details .button-box .fa-heart {
  color: #ed3237;
}
.product-details .button-box:hover {
  color: #fff;
  border-color: #ed3237;
}

.product-details .button-box:hover .fa-map-marker-alt,
.product-details .button-box:hover .fa-info-circle,
.product-details .button-box:hover .fa-heart {
  color: #ffffff;
}

.product-details .product-single-collapse .collapse-body-wrapper {
  padding: 15px;
  background: #ffffff;
}
.product-details .product-single-collapse .collapse-body-wrapper p {
  margin: 0px;
}
.product-details .product-size-content {
  padding-top: 0px;
  margin: 0;
  padding-bottom: 0;
}
.products-section .owl-nav {
  opacity: 0;
}
.products-section .main-heading h2:before {
  margin: 0px;
}
.products-section h2 {
  border-bottom: none;
}

.product-single-container .owl-carousel .owl-stage .owl-item.active .owl-dot {
  border: 1px solid #cdcccc;
}
.product-single-collapse .bIhMYT {
  width: 100%;
}
.product-single-collapse .eanPte {
  background: #ffffff;
}
.product-single-collapse .eanPte p {
  padding: 0px;
}
/*********************** END ***********************/

/***************** Login Page *****************/
.offerbanner {
  padding: 100px 20px 0px 20px;
}
.hr-form form {
  margin: 0px;
}
.hr-form {
  background: #ffffff;
  position: relative;
  padding: 30px;
  border-radius: 8px;
  margin-top: -80px;
  box-shadow: 2px 3px 6px #c5bebe;
  margin-bottom: 50px;
}
.hr-form:before {
  position: absolute;
  top: 0px;
  right: 0px;
  content: "";
  background: url(../image/form-shape.png) no-repeat;
  background-position: top right;
  width: 267px;
  height: 70px;
  border-top-right-radius: 8px;
}
.hr-form .main-heading h2:before {
  right: inherit;
  bottom: -16px;
}
.hr-form h1 {
  font-size: 35px;
  color: #ed3237;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 42px;
  margin: 20px 0px 35px;
}
.hr-form h1 strong {
  color: #373435;
  font-weight: 800;
  display: block;
}
.hr-form .form-group {
  position: relative;
  margin-bottom: 2rem;
}
.hr-form .form-group .fa {
  position: absolute;
  top: 16px;
  left: 14px;
  font-size: 16px;
}
.hr-form .form-control {
  border: none;
  background: #fafafa;
  box-shadow: 0px 1px 1px #bbbbbb;
  border-radius: 4px;
  padding: 10px 40px;
  font-size: 15px;
  height: 50px;
}
.hr-form input[type="submit"] {
  border: none;
  border-radius: 4px;
  background: #ed3237;
  color: #fff;
  padding: 10px 0px;
  margin: 30px auto 10px;
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  width: 160px;
  cursor: pointer;
  display: block;
}
.hr-form input[type="submit"]:hover {
  background: #0a0707;
}
.hr-form .form-control:focus {
  background: #ededed;
}
/*********************** END ***********************/

/***************** About Us *****************/
.aboutpage h2:before {
  right: inherit;
}
.page_container,
.other {
  margin: 50px auto;
  padding-left: 180px;
}
.jumpto-block {
  background: #fff;
  padding: 20px;
  position: relative;
}
.jumpto-block:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 45px;
  height: 4px;
  background: #b2b2b2;
  content: "";
  margin: 0px auto;
}
.jumpto-cotainer {
  position: relative;
}
.jumpto-subnav {
  position: absolute;
  top: 0;
  left: 0px;
  width: 180px;
}
.jumpto-subnav .jumpto-second {
  display: none;
}
.jumpto-subnav.fixed {
  position: fixed !important;
  left: auto;
  top: 75px;
  margin-left: -180px;
}
.jumpto-subnav.fixed.bottom {
  top: auto;
  bottom: 0;
  position: absolute !important;
}
.jumpto-subnav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background: #ed3237;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.jumpto-subnav ul li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.jumpto-subnav ul li a {
  padding: 15px 10px;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: white;
  font-size: 18px;
  font-weight: 400;
}
.jumpto-subnav ul li a:hover,
.jumpto-subnav ul li a.active {
  background: #ffffff;
  color: #ed3237 !important;
  border-left: 8px solid #ed3237;
}
.jumpto-subnav ul > li > ul > li > a {
  padding-left: 15px;
}
.jumpto-title,
.jumpto-close a {
  background: #ed3237;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 10px;
  display: none;
}
.jumpto-title {
  border-top-left-radius: 4px;
}
.jumpto-close a {
  border-bottom-left-radius: 4px;
}
.jumpto-block h2 {
  font-size: 25px;
  font-weight: 500;
  color: #373435;
  position: relative;
}
.jumpto-block h2:before {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 45px;
  height: 3px;
  background: #ed3237;
  content: "";
  margin: 0px auto;
}
.jumpto-block p {
  color: #636363;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.jumpto-block b {
  font-weight: 500;
  font-style: italic;
}
.jumpto-close {
}
/******************* END *******************/

/***************** Bathroom Concepts *****************/
.bathroom-concepts h2:before {
  right: inherit;
}

/* Masonry grid */
.masonry {
  transition: all 0.5s ease-in-out;
  column-gap: 30px;
  column-fill: initial;
  margin-top: 50px;
}

/* Masonry item */
.masonry .brick {
  margin-bottom: 30px;
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.masonry .brick .theme-title {
  position: absolute;
  bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  color: #ffffff;
  left: 12px;
  right: 12px;
  background: #000;
  padding: 12px 18px;
  border-radius: 4px;
}

/* Masonry image effects */
.masonry .brick img {
  transition: all 0.5s ease-in-out;
  backface-visibility: hidden;
}
.masonry .brick:hover img {
  opacity: 0.75;
}

.masonry .brick:hover .theme-title {
  display: none;
}
.masonry .brick:hover .hoverBox {
  display: block;
}

.hoverBox {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background: rgb(0 0 0 / 60%);
  bottom: 0px;
  padding: 20px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  display: none;
  transition: all 0.5s ease-in-out;
}
.hoverBox h2 {
  color: #fff;
  position: relative;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 24px;
}
.hoverBox h2:before {
  width: 40px;
  height: 4px;
  background: #fff;
  margin: 0px auto;
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  content: "";
}
.hoverBox p {
  font-size: 15px;
  line-height: 22px;
  margin: 0px;
}

/* Bordered masonry */
.masonry.bordered {
  column-rule: 1px solid #cdcdcd;
  column-gap: 20px;
}
.masonry.bordered .brick {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #cdcdcd;
}

/* Gutterless masonry */
.masonry.gutterless {
  column-gap: 0;
}
.masonry.gutterless .brick {
  margin-bottom: 0;
}

/* Masonry on tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .masonry {
    column-count: 2;
  }
}

/* Masonry on big screens */
@media only screen and (min-width: 1024px) {
  .desc {
    font-size: 1.25em;
  }
  .intro {
    letter-spacing: 1px;
  }
  .masonry {
    column-count: 3;
  }
}

.bor-btn {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  border: 1px solid #ffffff;
  margin-top: 20px;
  padding: 4px 12px;
}
.bor-btn:hover {
  background: #373435;
  color: #ffffff;
  border-color: #373435;
}
/******************* END *******************/

/***************** Catalgoue *****************/
.Solution_downloads_card {
  min-height: 400px;
  max-height: 400px;
}
.catalogue-box {
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  position: relative;
  display: block;
}
.catalogue-box:hover {
  border-color: transparent !important;
}
.catalogue-box:hover span .fa {
  background: #ed3237;
  color: #ffffff;
}
.catalogue-img {
  text-align: center !important;
}
.catalogue-img img {
  display: inline-block;
}
.catalogue-title {
  position: absolute !important;
  left: 7% !important;
  width: 85%;
  bottom: 20px;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  display: none;
}
.catalogue-title h4 {
  color: #fff;
  margin: 0;
  padding: 5px;
  font-size: 15px;
  font-weight: 400 !important ;
}
.catalogue-title a {
  color: #fff;
}

.newLogoCataloguePage {
  position: absolute;
  width: 45px;
  top: 10px;
  left: 10px;
}

ul.catalogue-icn {
  margin: 0;
  padding: 5px;
}
ul.catalogue-icn li {
  list-style: none;
  padding: 0 10px;
  display: inline-block;
}
ul.catalogue-icn li:after {
  content: "|";
  margin-left: 12px;
}
ul.catalogue-icn li:after:last-child {
  content: "";
}
ul.catalogue-icn-detail {
  margin: 0;
  padding: 5px;
}
ul.catalogue-icn-detail li {
  list-style: none;
  padding: 5px;
  border-bottom: 1px solid #424140;
  font-size: 14px;
}
ul.catalogue-icn-detail li:last-child {
  border-bottom: none;
}
.catalogue-detail-overlay {
  position: absolute;
  height: 0%;
  width: 0%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}
.catalogue-box:hover .catalogue-detail-overlay {
  top: 0;
  height: 100%;
  width: 100%;
}
.catalogue-detail {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  color: #fff;
  width: auto;
  text-align: left;
  padding: 10px;
  font-size: 17px;
}
.catalogue-detail a {
  color: #ffffff !important;
}
.catalogue-detail a:hover {
  color: #bcbcbc !important;
  text-decoration: underline;
}
.catalogue-box:hover .catalogue-title {
  display: none;
}
.solutionpage-listing {
  margin-top: 0;
}
.innerpage-sk .tabs {
  padding: 0px !important;
  margin-top: 50px;
}
#tab-button {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
#tab-button li {
  display: table-cell;
  width: 20%;
}
#tab-button li a {
  display: block;
  padding: 0.5em;
  background: #fff;
  border: 0px solid #ddd;
  text-align: center;
  color: #000;
  text-decoration: none;
}
#tab-button li:not(:first-child) a {
  border-left: none;
}
#tab-button li a:hover,
#tab-button .is-active a {
  border-bottom-color: transparent;
  border-bottom: 2px solid #ed3237; /* background: #ed3237; */ /* color:#fff; */
}
.tab-contents {
  padding: 0.5em 2em 1em; /* border: 1px solid #ddd; */
}
.tab-select-outer {
  font-size: 15px;
  font-weight: 600;
}
#tab-select {
  color: #ffffff;
  background: #ed3237;
  padding: 8px 16px; /* font-size: 15px; */
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
  text-transform: capitalize;
}
.tab-button-outer {
  display: none;
}
.tab-contents {
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .tab-button-outer {
    width: 50%;
    position: relative;
    z-index: 2;
    display: block;
  }
  .tab-select-outer {
    display: none;
  }
  .tab-contents {
    position: relative;
    top: -1px;
    margin-top: 0;
  }
}
/******************* END *******************/

/***************** Solution *****************/
.solutionpage h2:before {
  right: inherit;
}
.tab-select-outer {
  font-size: 15px;
  font-weight: 600;
}
#tab-select {
  color: #ffffff;
  background: #ed3237;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}
.tab-button-outer {
  display: none;
}
.solutionpage .tab-contents {
  margin-top: 20px;
  padding: 0px !important;
}
.solutionpage-listing {
}
.solutionpage-listing .tab-button-outer {
  width: 100%;
  background: #fff;
  padding: 0px 15px;
  box-shadow: -1px 2px 3px #cfcfcf;
  border-radius: 5px;
  margin-bottom: 30px;
}
.solutionpage-listing .tab-button-outer ul {
  padding: 0;
  margin: 0;
  text-align: center;
}
.solutionpage-listing .tab-button-outer ul li {
  display: inline-block !important;
  width: auto !important;
}
.solutionpage-listing .tab-button-outer ul li a {
  display: block !important;
  margin: 0px 45px;
  color: #473a1c !important;
  font-weight: 400 !important;
  font-size: 18px;
  padding: 15px 0px !important;
}
.solutionpage-listing .tab-button-outer ul li a:hover {
  color: #ed3237;
  border-bottom: 2px solid #ed3237;
}
.solutionpage-listing .tab-button-outer ul li.is-active a {
  color: #ed3237 !important;
  font-weight: 600 !important;
  border-bottom: 2px solid #ed3237;
}
.solutionsBox {
  position: relative;
  margin-bottom: 120px;
}
.solutionsBox-detail {
  width: 250px;
  background: #fff;
  padding: 25px;
  border-radius: 4px;
  position: absolute;
  bottom: -65px;
  left: 70px;
  box-shadow: 0px 0px 5px #7a7a7a;
}
.solutionsBox-detail h3 {
  color: #373435;
  font-size: 26px;
  position: relative;
  font-weight: 700;
}
.solutionsBox-detail h3:before {
  position: absolute;
  bottom: -8px;
  left: 0;
  background: #ed3237;
  height: 4px;
  width: 44px;
  content: "";
}
.solutionsBox-detail p {
  color: #2b2a29;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.solutionsBox-detail a {
  border: 1px solid #2b2a29;
  color: #2b2a29;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  padding: 4px 12px;
  border-radius: 2px;
}
.solutionsBox-detail a:hover {
  background: #ed3237;
  border-color: #ed3237;
  color: #ffffff;
}
.solutionsBox-right {
  right: 70px;
  left: inherit;
}

.solutionpage-sidemenu .cat-list li a {
  color: #636363;
  position: relative;
  font-weight: 500;
}
.solutionpage-sidemenu .cat-list li a:hover {
  color: #ed3237;
}
.solutionpage-sidemenu .cat-list li a:before {
  position: absolute;
  top: 3px;
  left: 0;
  font: normal normal normal 14px/1 FontAwesome;
}
.solutionpage-sidemenu .cat-list li.sidemenu-active a {
  color: #ed3237;
}

@media screen and (min-width: 768px) {
  .tab-button-outer {
    width: 50%;
    position: relative;
    z-index: 2;
    display: block;
  }
  .tab-select-outer {
    display: none;
  }
  .tab-contents {
    position: relative;
    top: -1px;
    margin-top: 0;
  }
}
/******************* END *******************/

/***************** Solution Details *****************/
.bathroom-cost-box {
  position: relative;
}
.bathroom-cost-box .blackstrip {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  background: #000000cf;
  padding: 10px 20px;
}
.bathroom-cost-box .blackstrip h2 {
  color: #fff;
  margin: 0px;
  padding: 8px 0px;
  position: relative;
}
.bathroom-cost-box .blackstrip h2:before {
  position: absolute;
  bottom: 3px;
  left: 0;
  background: #ffffff;
  height: 4px;
  width: 44px;
  content: "";
}
.bathroom-cost-box .blackstrip a {
  display: block;
  color: #fff;
  font-size: 22px;
  line-height: 34px;
}
.bathroom-cost-box img {
  width: 100%;
}
.bathroom-cost-box .blackstrip a.explorebutton {
  position: relative;
  top: 15px;
}

.whitesolutionBox {
  padding: 25px;
  box-shadow: 0px 0px 4px #d3d3d3;
  background: #fff;
  margin-top: 30px;
  border-radius: 4px;
}
.whitesolutionBox .solutionsBox-detail {
  width: auto;
  padding: 0px;
  box-shadow: none;
  position: inherit;
  left: inherit;
  bottom: inherit;
}

.solutionsBox-detail .card.card-accordion .card-header {
  border: none;
  border-bottom: 1px solid #d2d2d2;
  color: #373435;
}
.solutionsBox-detail .card.card-accordion p {
  font-size: 15px;
  font-weight: 400;
  color: #837e78;
  line-height: 22px;
}

.testimonials-big-type .testimonial {
  display: flex;
  margin-top: 30px;
}
.testimonials-big-type .testimonial figure {
  margin-right: 3rem;
  width: 120px;
}
.testimonials-big-type .testimonial img {
  min-width: 80px;
  border-radius: 50%;
}
.testimonials-big-type .testimonial .testimonial-owner {
  max-width: 930px;
}
.testimonials-big-type .testimonial .meta {
  padding-top: 0;
}
.testimonials-big-type .testimonial .testimonial-title {
  margin-bottom: 3px;
  font-size: 16px;
  font-weight: 600;
  text-transform: unset;
  color: #2b2a29;
  margin-top: 6px;
  position: relative;
}
.testimonials-big-type .testimonial .testimonial-title:before {
  width: 50px;
  height: 1px;
  content: "";
  background: #ed3237;
  position: absolute;
  top: 0;
}
.testimonial blockquote {
  margin-left: 0px;
  padding: 0px;
}
.testimonial blockquote:before {
  display: none;
}
.testimonials-big-type .testimonial span {
  font-weight: 400;
  font-style: italic;
  color: #2b2a29;
  text-transform: capitalize;
}
.solutionsBox-detail .testimonial blockquote p {
  color: #2b2a29;
  font-weight: 400;
}
.solutionsBox-detail .owl-theme .owl-nav[class*="owl-"] {
  display: none;
}
.solutionsBox-detail .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
  position: absolute;
  top: 120px;
  left: 20px;
}

.experience-center {
  margin-top: 30px;
  position: relative;
}
.experience-center img {
  width: 100%;
}
.experience-center-detail {
  position: absolute;
  top: 40px;
  right: 30px;
  background: #000000de;
  padding: 20px;
  width: 300px;
  color: #fff;
}
.experience-center-detail h2 {
  font-size: 26px;
  line-height: 52px;
  color: #fff;
  position: relative;
  font-weight: 700;
}
.experience-center-detail h2:before {
  position: absolute;
  bottom: -8px;
  left: 0;
  background: #ffffff;
  height: 4px;
  width: 44px;
  content: "";
}
.experience-center-detail a {
  color: #ffffff;
}

.tablink {
  text-align: center;
  padding-top: 20px;
}
.tablink ul {
  padding: 0px;
  margin: 0px;
}
.tablink li {
  display: inline-block;
  vertical-align: top;
  width: 31%;
}
.tablink li a {
  color: #5a5959;
  font-size: 18px;
  font-weight: 700;
  display: block;
  background: #fff;
  box-shadow: 0px 0px 4px #d3d3d3;
  border-radius: 4px;
  margin: 0px 10px;
  padding: 12px 0px;
  position: relative;
}
.tablink li a:hover {
  background: #ed3237;
  color: #fff;
}
.tablink li a .fa {
  position: absolute;
  top: 0;
  left: 0px;
  background: #ed3237;
  width: 22%;
  height: 100%;
  line-height: 50px;
  color: #fff;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
/******************* END *******************/

/* Homepage slider section */
.solutionpage-listing-right li {
  list-style: disc;
  margin-left: 15px;
}
.international-banner-home {
  padding: 10px 0px !important;
}
.jumpto-block p {
  text-align: justify !important;
}
.arivalcss {
  margin-bottom: 30px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #00000030 !important;
  width: 30px;
  height: 50px;
  border-radius: 2px;
  background-size: 70%;
}
.sc-jgrJph h2 {
  margin: 0;
  font-weight: 400 !important;
}
.jRjVRR {
  font-size: 25px !important;
  line-height: 1.5 !important;
  padding-top: 40px !important;
  font-weight: 400 !important;
}
.bOitxk {
  text-transform: uppercase;
}
.cdUROa {
  padding: 4% 8% 2%;
}
.sc-cidDSM img {
  margin-bottom: 20px;
  display: block;
}
.back-color-register h2 {
  font-weight: 400;
  font-size: 30px;
}
.back-color-register label {
  font-weight: 500;
  font-size: 16px;
}
.back-color-register p {
  padding: 0;
  margin: 0px 0px 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}
.positionofsearch .margin5px {
  font-size: 16px;
  margin-right: 0px;
}
.innerdivconcept {
  padding: 4%;
  text-align: center;
}
.innerdivconcept a {
  margin-top: 20px;
}
.innerdivheading {
  text-transform: capitalize;
}
.innerbtnconcept {
  padding: 8px 20px;
  margin: 20px auto 0px;
  font-size: 18px;
  border-radius: 0px;
}
.bathroomconceptdiv {
  position: relative;
  padding-bottom: 0px;
  background: #ffffff;
}
.international-redirect {
  margin-bottom: 10px;
}
.germfreediv {
  margin-bottom: 10px;
}
.listing .erogAO,
.listing .brcOzo {
  font-weight: 400;
  font-size: 25px !important;
  line-height: 1.5 !important;
}
#tab-button li a {
  font-size: 18px;
}
.back-color-register {
  height: auto;
}
.innerpage-sk .back-color-register {
  padding-top: 30px;
}

.newLogoCategoriesPage {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 45px;
}

.icongreather {
  height: 35px;
  width: 35px;
  border-radius: 50% !important;
}
.innerdivheading {
  font-size: 25px !important;
  font-weight: 400 !important;
}
.sidebar-shop input[type="radio"] {
  top: 12px !important;
}
.post-box {
  height: 100%;
  background: #fff;
}
.reginal-numbers .col-sm-3 {
  height: 100%;
}
.reginal-numbers .col-12 {
  padding: 0;
}
.reginal-numbers b {
  display: block;
}
.reginal-numbers h2 {
  font-size: 30px;
  font-weight: 500;
  margin: 60px 0px 0px;
  border-bottom: 2px solid #cdcccc;
  padding: 10px;
  background: #fff;
  text-align: center;
}
.reginal-numbers h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 10px 0px 20px;
  border-top: 1px solid #bbb8b8;
  padding: 30px 10px 0px;
}
.reginal-numbers h3:first-child {
  border-top: none;
}
.reginal-numbers .city-section {
  margin-bottom: 35px !important;
}

.reginal-numbers b {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px;
  border-bottom: 1px solid #cdcccc;
  padding: 6px 10px;
  background: #e3dfdf;
}
.reginal-numbers span {
  display: block;
  margin-bottom: 6px;
}
.reginal-numbers span svg {
  margin-right: 8px;
  vertical-align: middle;
}
.modal-press-release .modal-content {
  background: none;
}
/* .press-release-card-sk {position: relative;height: 380px;text-align: center;}
 */
.press-release-card-sk {
  position: relative;
  height: 380px;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}
/* .press-release-card-sk img {width: auto;height: 100%;display: inline-block;} */
.press-release-card-sk svg {
  z-index: 1;
  background: #ed3237;
  color: #fff;
  width: 35px;
  height: 32px;
  display: block;
  bottom: 0px;
  right: 0px;
  position: absolute;
  padding: 8px;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
}
.modal-press-release {
  background: #000000d6 !important;
  padding: 0px !important;
}

.main-seaction-press .press-main-section {
  margin: 0px !important;
  padding: 20px !important;
  position: relative;
}
.main-seaction-press .press-main-section img {
  width: auto !important;
  height: 100%;
  display: inline-block !important;
  background: #fff;
  padding: 10px;
  border-radius: 4px;
}
.main-seaction-press {
  padding: 0px !important;
  margin: 0px !important;
  background: none !important;
  text-align: center !important;
  overflow-y: inherit;
}
.press-main-section .owl-nav.disabled {
  display: block !important;
}
.main-seaction-press .press-main-section {
  margin: 0px !important;
  padding: 20px !important;
}
.press-main-section .owl-nav button.owl-prev {
  color: #fff !important;
  font-size: 50px !important;
  cursor: pointer !important;
  position: absolute !important;
  top: 50% !important;
  left: -40px !important;
}
.press-main-section .owl-nav button.owl-next {
  color: #fff !important;
  font-size: 50px !important;
  cursor: pointer !important;
  position: absolute !important;
  top: 50% !important;
  right: -40px !important;
}
.brands-section svg.icon-press-release {
  position: absolute;
  top: 5px;
  right: 0px;
  z-index: 999;
  background: #fff;
  color: #242424 !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 6px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
}
.awards-accolades {
  width: 100%;
  text-align: center !important;
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  height: 90%;
  background: #ffffff;
  border-radius: 10px;
}
.awards-heading h2::before {
  right: inherit !important;
}
/* .awards-accolades strong {display: block !important;font-weight: 400;padding: 15px;} */
.awards-accolades strong {
  display: block !important;
  font-weight: 400;
  padding: 10px;
  color: #242424;
  font-size: 15px;
}
.newarrivals-slider .owl-carousel .owl-nav {
  display: block !important;
  position: relative;
}
.newarrivals-slider .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: -175px;
  font-size: 40px;
  background: grey !important;
  width: 22px;
  height: 50px;
  color: #fff;
  font-weight: 100;
  left: -28px;
}
.newarrivals-slider .owl-carousel .owl-nav button.owl-next {
  position: absolute;
  top: -175px;
  font-size: 40px;
  background: grey !important;
  width: 22px;
  height: 50px;
  color: #fff;
  font-weight: 100;
  right: -28px;
}
.newarrivals-slider .owl-dots {
  display: none !important;
}
.awards-accolades img {
  display: inline-block !important;
  margin-top: 15px;
}
.textformate span {
  display: block;
  font-size: 20px;
  margin-bottom: 10px;
  color: #000;
  font-weight: 500;
}
.textformate p {
  color: #393939;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  margin-bottom: 20px;
}
ul.customersupport {
  padding: 0;
  margin: 0;
  text-align: right;
}
ul.customersupport li {
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
}
.textformate ul {
  padding: 0px;
  margin: 0px;
}
.textformate ul li {
  margin-bottom: 10px;
  text-align: justify;
  color: #393939;
  font-weight: 500;
  font-size: 14px;
}
.textformate hr {
  height: inherit;
  margin: 0 0 15px;
}
.sharelocation .modal-dialog {
  max-width: 550px;
}
.sharelocation .text-share-experiance {
  padding: 0px 0px 20px 0px !important;
}
.sharelocation .modal-content {
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 18px 5px #0003;
}
.sharelocation .modal-content h3 b {
  font-weight: 500;
}
.grid figure {
  background: transparent;
}
.post-body {
  padding: 20px;
  background: #fff;
}
.post-media {
  margin: 0px;
}
.bathcost h2 {
  line-height: normal;
}
.post .read-more {
  float: left;
  text-transform: capitalize;
  color: #e13b3f;
  font-size: 15px;
}

.faq-section .dropdown {
  margin-bottom: 10px;
  border-bottom: 1px solid #b3b3b3;
}
.faq-section .dropdown button p {
  background: none;
}
.faq-section .dropdown p {
  margin: 0px 0px 15px;
  background: #fff;
  line-height: 28px;
  font-size: 16px;
}
.bdrbrand {
  background: #ffffff;
  padding: 10px 0px;
}
.bdrbrand img.our-brands-hd {
  width: auto !important;
  height: 70px !important;
}
.tittle-press {
  color: #000;
  font-weight: 500;
  padding: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  background: #dedcdc;
  font-size: 15px;
  line-height: normal;
  height: 110px;
}
.bathroom-concept-explore .icon-back-back {
  position: absolute;
  top: 5%;
  left: 5%;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  background: #ed3237;
  border-radius: 50%;
  padding: 8px;
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.detail-zoom-img {
  min-height: 407px;
  width: 100%;
}
/* .bathroom-concept-explore{
    position: relative;
  }
  .icon-back-back{
    position: absolute;
    top: 5%;
    left: 5%;
    color: white;
    font-weight: 600;
    font-size: 25px;
    cursor: pointer;
  } */
.Heding-tab-name {
  text-transform: capitalize;
}
.none-tabs {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.pin-wrapper p {
  font-size: 10px !important;
}
/* .banner-homepage-sk{
  padding-top: 60px;
} */

/* Bathroom Concepts */
.brickconcept {
  min-height: inherit !important;
  max-height: inherit !important;
}
.solutionBox {
  padding: 0;
}
.solutionBox h2 {
  font-weight: 400;
  font-size: 25px;
}
.solutionBox strong {
  font-weight: 400;
  margin-bottom: 15px;
  display: block;
}
.brickconcept .hoverBox .bor-btn {
  font-weight: 400 !important;
}
.solutionpage-sidemenu .cat-list li a:before {
  display: none !important;
}
.checkout-methods .submit_button {
  font-weight: 400;
}
.checkout-methods button {
  font-weight: 400;
}
.post-meta > span {
  display: none !important;
}
.blog-section .post-media img {
  width: auto;
  height: 120px;
  margin: 0px auto;
}

/***************** Dealer Locator *****************/
.nearestdealer {
  background: #fff;
  border-radius: 6px;
  margin: 50px 0px;
}
/* .nearestdealer form{margin: 0px;padding: 10px;}*/
.nearestdealer .ec_form {
  margin: 0px;
  padding: 10px;
}
.nearestdealer .form-group {
  overflow: hidden;
  margin: 0;
  position: relative;
}
.nearestdealer .form-group .fa {
  position: absolute;
  top: 12px;
  left: 70px;
  font-size: 25px;
  color: #949494;
}
.nearestdealer h3 {
  margin: 0;
  padding: 20px 10px;
  background: #ed3237;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  text-align: center;
}
/* .nearestdealer form label {float: left;margin: 0;padding: 0;width: 20%;font-size: 18px;font-weight: 400;color: #373435;line-height: 50px;} */
/* .nearestdealer .ec_form label {float: left;margin: 0;padding: 0;width: 20%;font-size: 18px;font-weight: 400;color: #373435;line-height: 50px;} */
.nearestdealer .ec_form label {
  margin: 0px 0px 5px;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #373435;
  display: block;
}
.nearestdealer .ec_form button {
  width: 100%;
  margin-top: 24px;
}
.nearestdealer .form-group select {
  /* float: right; */
  width: 100% !important;
  height: 5rem !important;
  padding: 0px 15px;
  font-size: 15px;
}
.nearestdealer .form-group select option {
  padding: 0px;
}
.dealer-box {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 4px #00000040;
  padding: 25px;
  margin-bottom: 40px;
}
.dealer-box h5 {
  font-size: 22px;
  color: #373435;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0px 0px 5px;
  padding: 0;
}
ul.addresspoint {
  padding: 0;
  margin: 0;
}
ul.addresspoint li {
  position: relative;
  padding: 10px 0 10px 40px;
  font-size: 15px;
  color: #636363;
  font-weight: 500;
}
ul.addresspoint li a {
  color: #636363;
  word-break: break-word;
}
ul.addresspoint li a:hover {
  color: #ed3237;
}
ul.addresspoint li .fa {
  position: absolute;
  top: 12px;
  left: 0;
  font-size: 20px;
  margin: 0px auto;
  text-align: center;
  width: 30px;
  color: #7c7777;
}
ul.moreinfo {
  margin: 20px 0 0;
  padding: 0;
  text-align: center;
}
ul.moreinfo li {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 23.5%;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 0px 2px;
  font-size: 15px;
  color: #636363;
  font-weight: 400;
}
ul.moreinfo li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 8px 0;
}
ul.moreinfo li:hover,
ul.moreinfo li:hover a,
ul.moreinfo li:hover label,
ul.moreinfo li:hover .fa {
  border-color: #ed3237;
  color: #ed3237;
}
ul.moreinfo li .fa {
  font-size: 20px;
  color: #636363;
}
ul.moreinfo li label {
  display: block;
  color: #636363;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
}

.footer b {
  font-weight: 400;
  font-size: 16px;
}

.innerpage-sk .main-heading h2:before {
  right: inherit;
}

.innerpage-sk .main-heading h1:before {
  right: inherit;
}

.innerpage-sk .main-heading {
  margin-top: 30px;
}

.product-single-details .productHeading{
  margin: 0px !important;
}

.innerpage-sk .detailpageHeading {
  display: inline-flex;
}
.main-heading h2 {
  margin-bottom: 30px;
}

.main-heading h1 {
  margin-bottom: 30px;
}
.locateus .main-heading h2:before {
  right: 0px !important;
}
.eventpost {
  background: #fff;
  height: 100%;
  margin: 30px 0px 0px;
}
.eventpost .post-box .post-media {
  height: 400px;
  text-align: center;
}
.eventpost .post-box .post-media img {
  width: auto;
  height: 100%;
  display: inline-block;
}
.eventpost .events-icon {
  right: 0;
  bottom: 0;
  width: 35px;
  padding: 8px;
  height: 32px;
  border-radius: 0px;
}
.eventpost .post-box h2.post-title p {
  margin: 0;
}
.international-menu-center {
  display: flex;
  justify-content: left;
}
.product-details {
  position: relative;
}
/* .product-detail-germfree{
  position: absolute;
  top: 2%;
  right: 0%;
  background-color: #000;
  z-index: 111111111;

} */

.product-detail-germfree-detail {
  position: absolute;
  top: 60px;
  right: 0%;
  display: flex;
}

.product-detail-germfree-detail span {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .Solution_downloads_card {
    min-height: 400px !important;
    max-height: 400px !important;
  }
  .solutionpage-listing-right li {
    list-style: disc;
    margin-left: 15px;
  }
  .main-seaction-press .press-main-section img {
    height: 250px;
  }
  .product-detail-germfree-detail {
    top: 90px;
  }
}
.product-detail-germfree-detail img {
  width: 100px;
}

.newLogoDetailPage {
  width: 45px !important;
}

.requestforprice textarea.form-control {
  min-height: 90px !important;
}
.requestforprice .h4 {
  margin: 0;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}
/******************* END *******************/

/***************** Custom Responsive *****************/
@media only screen and (max-width: 767px) {
  .under_description_products h2 {
    font-size: 20px;
  }
  ul.bradscrumb-nav li a.active {
    /* margin-left: 11px; */
    color: #fff;
  }
  .state-padding {
    padding-top: 10px !important;
  }
  .main {
    padding-top: 70px;
  }
  .innerpage-sk .main-heading {
    margin-top: 10px;
  }
  .innerpage-sk .detailpageHeading {
    display: inline-flex;
  }
  .banner {
    position: relative;
    padding-top: 0px;
  }
  .listing {
    margin: 0px !important;
  }
  .kLJNzr {
    padding-top: 20px;
  }
  .delearimage {
    display: none !important;
  }
  .contain-img-div {
    min-height: inherit;
    max-height: inherit;
  }
  .home-slide {
    min-height: 150px;
  }
  .main-heading h2 {
    font-size: 25px;
  }
  .main-heading h1 {
    font-size: 25px;
  }
  .para-padd p {
    padding: 0px 20px;
  }
  .home-about img {
    width: 100%;
    margin-bottom: 15px;
    height: auto;
  }
  .home-about .main-heading a {
    margin-bottom: 30px;
  }
  .germfree {
    padding: 20px;
    height: auto;
    margin: 40px 0px 0px;
  }
  .locateus {
    margin-bottom: 20px;
  }
  .knowledgesupport .blackstrip a {
    text-align: left;
  }
  .loyalty h2 {
    margin-bottom: 30px;
  }
  .loyalty ul li {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .breadscrumb {
    position: inherit;
    right: 0;
    bottom: 0;
    padding: 10px;
  }
  ul.bradscrumb-nav li {
    padding: 0px 0px 0px 10px;
  }
  .padding-top {
    margin-top: -70px !important;
  }
  .loyalty {
    padding: 0px 15px;
  }
  .loyalty ul {
    padding: 0px;
    margin: 30px 0px;
  }
  .innerdivconcept {
    top: 0 !important;
    left: 0 !important;
    position: inherit !important;
    opacity: 1 !important;
  }
  .knowledgesupport-box {
    margin-top: 20px;
  }
  .knowledgesupport-box .blackstrip h2 {
    padding: 0px;
  }
  .banner-homepage-sk {
    padding-top: 0px;
  }
  .tittle-press {
    height: auto;
    line-height: normal;
    background: #e1e1e1;
    font-size: 16px;
  }
  .press-release-card-sk {
    align-items: flex-start;
  }
  .press-main-section .owl-nav button.owl-next {
    right: -20px !important;
    color: white !important;
    top: 35% !important;
    background: #000000d6 !important;
  }
  .press-main-section .owl-nav button.owl-prev {
    left: -20px !important;
    color: white !important ;
    top: 35% !important;
    background: #000000d6 !important;
  }
  .product-details .product-action {
    border-top: none;
    padding-top: 0px;
    display: inline-block;
  }

  /* Homepage Product Categories 01-12-2021 */
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    background: #fff;
    /* padding: 10px !important; */
  }
  .owl-theme .owl-nav[class*="owl-"] {
    width: 100%;
  }
  .owl-carousel .owl-nav .owl-prev {
    position: absolute;
    left: 0;
  }
  .owl-carousel .owl-nav .owl-next {
    position: absolute;
    right: 0;
  }
  figure.effect-moses p {
    line-height: 18px !important;
    padding: 10px !important;
  }
  figure.effect-moses h2 {
    padding: 60px 8px !important;
    font-size: 18px !important;
  }
  .new-arrivals .creative-grid {
    padding-top: 10px;
  }
  .new-arrivals {
    padding: 30px 0px;
  }
  .brandsection {
    padding: 40px 0px 0px;
  }
  .reginal-numbers .city-section {
    height: auto;
  }

  /* 25/04/2022 */
  .productcategories button.owl-prev,
  .productcategories button.owl-next {
    background: #252525 !important;
    width: 15px;
    height: 35px;
    color: #fff !important;
  }
  .creative-grid .product-category figure img {
    height: 250px;
  }
  .creative-grid .product-category.content-left-bottom .category-content {
    margin-bottom: 0px;
  }
  /*Homepage End*/

  /*Login Page*/
  .offerbanner {
    padding: 20px 0;
  }
  .hr-form {
    margin-top: 0px;
  }
  .hr-form h1 {
    font-size: 36px;
    line-height: 35px;
    margin: 40px 0px 10px;
  }
  /*Login Page End*/

  /*About Page*/
  .jumpto-subnav {
    position: inherit !important;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    display: none;
  }
  .jumpto-subnav.fixed {
    position: inherit !important;
    left: inherit;
    top: inherit;
    margin-left: 0;
    display: none;
  }
  .page_container,
  .other {
    padding-left: 0px;
  }
  /*About Page End*/

  /*Solution Page*/
  .solutionpage {
    padding-top: 20px;
  }
  .solutionsBox {
    margin-bottom: 0px;
  }
  .solutionpage-listing {
    margin-top: 0px;
  }
  .solutionsBox-detail {
    position: inherit;
    bottom: 40px;
    left: 10px;
    right: 10px;
    width: 95%;
    padding: 20px;
    box-shadow: none;
  }
  .solutionsBox-right {
    position: inherit;
    bottom: 40px;
    left: 10px;
    right: 10px;
  }
  #tab-select {
    width: 100%;
  }
  .solutionpage {
    margin-top: 0px !important;
  }

  /*Solution Detail*/
  .bathroom-cost-box .blackstrip {
    position: relative;
  }
  .bathroom-cost-box .blackstrip h2 {
    font-weight: 400;
    font-size: 20px;
  }
  .bathroom-cost-box .blackstrip a.explorebutton {
    top: 5px;
    text-align: left;
  }
  .whitesolutionBox .solutionsBox-detail {
    right: inherit;
    margin-top: 10px;
  }
  .testimonials-big-type .testimonial {
    display: block;
    text-align: center;
  }
  .solutionsBox-detail .owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
    position: inherit;
    top: 20px;
    left: 0;
  }
  .testimonials-big-type .testimonial figure {
    margin: 0px auto 20px;
  }
  .experience-center-detail {
    position: inherit;
    top: 0;
    right: 0;
    width: auto;
  }
  .tablink li {
    display: block;
    width: auto;
  }
  .tablink li a {
    margin: 0px 0px 10px;
  }

  /* 21/4/2022 */
  section {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  a.logo img {
    width: 125px !important;
    display: block;
    max-width: 220px;
    height: auto !important;
  }
  .header-search {
    margin-left: 0px;
    margin-right: 10px;
  }
  .knowledgesupport .blackstrip h2 {
    padding: 0px;
  }
  ul.customersupport {
    text-align: left;
  }
  ul.customersupport li {
    margin-left: 0px;
    margin-right: 10px;
  }
  .blackstrip a.explorebutton {
    display: none;
  }
  .blackstrip .invisible {
    visibility: visible !important;
  }
  .loyalty a.downloadapp {
    width: 100%;
  }
  .footer {
    padding: 50px 15px 0px;
  }
  .footer ul li {
    font-size: 15px;
  }
  .footer b {
    font-size: 18px;
    font-weight: 500;
  }
  .footer .eOyPfv {
    display: inline-block;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 18px;
    background-color: rgb(0 0 0 / 36%) !important;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 30px;
  }
  .error_message {
    text-align: left !important;
    color: #ed3237 !important;
  }

  /* 10-05-2022 */
  .other .page_container {
    margin: 30px auto;
  }
  .jumpto-block img {
    margin-bottom: 30px;
  }
  .eva-dNB {
    padding-top: 20px;
  }
  .product-slider-container .owl-carousel .owl-item img {
    display: inline-block;
    width: 100% !important;
    height: 100% !important;
  }
  .newarrivals-slider .owl-dots {
    display: none !important;
  }
  .newarrivals-slider button {
    background: #252525 !important;
    width: 15px;
    height: 35px;
    color: #fff !important;
  }
  .product-details ul.availablecolor {
    margin-top: 0px;
  }
  .inner-icon .ratings-container {
    margin: 0px;
  }
  .main-heading h2 {
    padding-top: 40px;
  }
  .main-heading h1 {
    padding-top: 40px;
  }
  .brands-section {
    padding: 0;
  }
  .jumpto-block {
    padding: 0px;
    background: none;
  }
  .jumpto-block .container-fluid {
    padding: 0px;
  }
  div#awards {
    padding: 0 !important;
  }
  .tabs {
    padding: 0px !important;
  }
  .sticky-header {
    padding: 0px !important;
  }
  .tab-select-outer {
    margin-bottom: 20px;
  }
  .offcanvas-title {
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  /*.experience-box h5{font-weight: 400; font-size: 15px;}
.experience-box ul.addresspoint li {padding: 10px 0 0px 32px;font-size: 12px;}*/
  .experience-box {
    height: auto !important;
  }
  ul.moreinfo li {
    width: 30%;
    padding: 5px 0 5px 0px;
    font-size: 12px;
  }
  ul.moreinfo li label {
    font-size: 14px !important;
  }
  ul.addresspoint li a {
    font-size: 12px;
  }
  .footer-links {
    padding: 0px 15px;
  }
  ul.bradscrumb-nav li a.active {
    padding-left: 0px;
  }
  .experience-box h5 {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .experience-box ul.addresspoint li {
    padding: 2px 0 2px 35px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  .experience-box ul.addresspoint li .fa {
    top: 4px !important;
    font-size: 16px !important;
    width: 20px !important;
  }
  .experience-box ul.moreinfo li {
    width: 30% !important;
  }
  .experience-box ul.moreinfo li a {
    padding: 0px !important;
  }
  .experience-box ul.moreinfo li .fa {
    font-size: 15px !important;
  }
  .experience-box ul.moreinfo li label {
    font-size: 12px !important;
  }
  .back-color-register h2 {
    font-size: 25px;
  }
  .cart-summary {
    margin-bottom: 50px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .para-padd p {
    padding: 0px 20px;
  }
  figure.effect-moses h2 {
    padding: 10px !important;
    font-size: 11px !important;
  }
  .grid figure h2 span {
    font-size: 14px !important;
  }
  .solutionpage-listing {
    margin-top: 0px;
  }
  .solutionpage-listing .tab-button-outer ul li a {
    margin: 0px 30px;
  }
  .main-nav .menu > li > a {
    font-size: 15px;
  }
  .category-content h3 {
    font-size: 13px;
  }
  .germfree a {
    font-size: 18px;
    line-height: 26px;
  }
  .locateus label {
    font-size: 12px;
  }
  .knowledgesupport .blackstrip h2 {
    font-size: 16px;
  }
  ul.customersupport li {
    margin-right: 8px;
  }
  .knowledgesupport .blackstrip a {
    font-size: 10px;
  }
  .blackstrip a.explorebutton {
    display: none;
  }
  .blackstrip .invisible {
    visibility: visible !important;
  }
  .loyalty ul li {
    width: 48%;
    margin-right: 10px;
  }
  .footer ul li {
    font-size: 13px;
  }
  .padding-top {
    margin-top: 20px;
  }
  /*.main{padding-top: 0px;}*/
  .catalogue-img img {
    height: 100%;
  }
  .cat-name {
    margin-top: 30px !important;
  }
  .main-nav .menu .dropdown-menu-menu svg {
    right: 10px !important;
  }
  .padding-top {
    margin-top: 20px !important;
  }
  #tab-button li a {
    font-size: 15px;
  }
  .newarrivals-slider .owl-carousel .owl-nav button.owl-next,
  .newarrivals-slider .owl-carousel .owl-nav button.owl-prev {
    top: -150px;
  }
  .newarrivals-slider .owl-carousel .owl-nav button.owl-prev {
    left: 0px !important;
  }
  .newarrivals-slider .owl-carousel .owl-nav button.owl-next {
    right: 0px !important;
  }
  .newarrivals-slider .owl-dots {
    display: none !important;
  }
  .innerdivconcept {
    left: 20%;
    right: 20%;
  }
  a.invisible {
    text-align: right !important;
    padding: 12px 0;
  }
  .brands-section {
    padding: 0px;
  }
  .brandsection .owl-carousel .owl-nav .owl-prev {
    right: 30px;
    top: -100px;
  }
  .brandsection .owl-carousel .owl-nav .owl-next {
    right: 10px;
    top: -100px;
  }
  .bdrbrand {
    padding: 0px;
  }
  .brandsection {
    padding: 40px 0 0;
  }
  .loyalty {
    padding: 30px 0;
  }
  .header-middle .header-center {
    padding: 0;
  }
  .tab-select-outer {
    margin-bottom: 20px;
  }
  .offcanvas-start {
    width: auto !important;
  }
  .custom-control {
    margin-top: 0px;
  }
  .offcanvas-title {
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 736px) and (max-width: 1025px) and (orientation: landscape) {
  .main {
    padding-top: 70px;
  }
  .main-nav .menu li a {
    padding: 8px 8px;
  }
  .main-nav .menu li a svg {
    color: #242424 !important;
    font-size: 8px;
  }
  .main-nav .menu > li svg {
    right: -2px;
  }
  .main-nav .menu > li > a {
    padding: 8px 8px;
  }
  .newarrivals-slider .owl-dots {
    display: none !important;
  }
  .main-nav .menu > li svg {
    right: -1px;
  }

  .offcanvas-title {
    font-size: 30px;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
  .custom-control {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bath-rooms {
  column-count: inherit !important;
}
.js-image-zoom__zoomed-image {
  z-index: 1 !important;
  /* border: 3px solid gray; */
  background-color: white;
}
.product-single-carousel {
  z-index: -1;
}

.react-pleayer {
  z-index: 1111111;
}

.product-single-gallery img {
  /* padding: 15%; */
  object-fit: scale-down;
}
.enlarged-image-container {
  background-color: white;
}
.page-link {
  cursor: pointer;
}
.jumpto-first li {
  cursor: pointer;
}

.knowledgesupport .blackstrip a {
  font-size: 15px;
}
.large_image {
  z-index: 111111111111111;
}

.testimonianl-image img {
  max-height: 400px;
  min-height: 400px;
}
.event-back {
  background: none !important;
}
.read-more-testi {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.read-more {
  cursor: pointer;
  color: #ed3237;
}
.multiple-images {
  /* min-width: 111px !important; */
  overflow: hidden !important;
}
.multi-images img {
  /* width: 111px !important; */
  min-width: 90px !important;
}
.icons-list-div {
  /* display: flex; */
  /* flex-wrap: wrap; */
  margin-right: 3px;
  padding: 10px !important;
}
.icons-main-div {
  display: flex;
  flex-wrap: wrap;
}
.icons-list-div img {
  width: 70px;
}
.specfic-detail {
  margin-right: 5px;
}
.small-img-detail {
  display: flex;
  justify-content: flex-start !important;
}
.Ross-icon {
  z-index: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 20px !important;
  height: 20px !important;
}
.bdrbrand {
  cursor: pointer;
}
.xmmmm {
  height: 47px;
}

.fontbg {
  font-size: 16px;
}

.blogcategories {
  background-color: #ffffff;
}

.blogcategories ul li {
  padding: 10px 15px;
  border-bottom: 1px dashed #cdcccc;
  cursor: pointer;
}

.blogcategories ul li:last-child {
  border-bottom: none;
}

.blogcategories ul li:hover {
  color: #e13b3f;
}


.blogs-section{
  padding-top: 30px;
}