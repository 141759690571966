/* Common style */
.enquiry {
  color: white !important;
}
.icon-technician {
  position: absolute;
  color: gray;
  font-size: 25px !important;
  top: 30%;
  right: 5%;
}
.icon-dealerlocater {
  position: absolute;
  top: 50%;
  right: 5%;
  color: gray;
  font-size: 25px;
}
.grid figure {
  background: #000000;
}
.icon-experiance {
  position: absolute;
  top: 54%;
  font-size: 25px;
  color: gray;
  right: 5%;
}
.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.8;
}
.margin-top-cat {
  margin-top: 1px !important;
}
.cat-name {
  margin-top: -80px !important;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}

.grid figure h2 span {
  font-weight: 800;
  font-size: 22px;
  display: block;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  font-size: 14px;
  line-height: normal;
}
.grid figure p span {
  display: block;
  text-decoration: underline;
  margin-top: 10px;
  font-style: italic;
}

/* Individual effects */

/*---------------*/
/***** Julia *****/
/*---------------*/

figure.effect-julia {
  background: #2f3238;
}

figure.effect-julia img {
  max-width: none;
  height: 400px;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-julia figcaption {
  text-align: left;
}

figure.effect-julia h2 {
  position: relative;
  padding: 0.5em 0;
}

figure.effect-julia p {
  display: inline-block;
  margin: 0 0 0.25em;
  padding: 0.4em 1em;
  background: rgba(255, 255, 255, 0.9);
  color: #2f3238;
  text-transform: none;
  font-weight: 500;
  font-size: 75%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-360px, 0, 0);
  transform: translate3d(-360px, 0, 0);
}

figure.effect-julia p:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

figure.effect-julia p:nth-of-type(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia p:nth-of-type(3) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:first-child {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

figure.effect-julia:hover p:nth-of-type(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-julia:hover p:nth-of-type(3) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-julia:hover img {
  opacity: 0.4;
  -webkit-transform: scale3d(1.1, 1.1, 1);
  transform: scale3d(1.1, 1.1, 1);
}

figure.effect-julia:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*-----------------*/
/***** Goliath *****/
/*-----------------*/

figure.effect-goliath {
  background: #df4e4e;
}

figure.effect-goliath img,
figure.effect-goliath h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-goliath img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-goliath h2,
figure.effect-goliath p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
}

figure.effect-goliath p {
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
}

figure.effect-goliath:hover img {
  -webkit-transform: translate3d(0, -80px, 0);
  transform: translate3d(0, -80px, 0);
}

figure.effect-goliath:hover h2 {
  -webkit-transform: translate3d(0, -100px, 0);
  transform: translate3d(0, -100px, 0);
}

figure.effect-goliath:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*-----------------*/
/***** Hera *****/
/*-----------------*/

figure.effect-hera {
  background: #303fa9;
}

figure.effect-hera h2 {
  font-size: 158.75%;
}

figure.effect-hera h2,
figure.effect-hera p {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
}

figure.effect-hera figcaption::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  border: 2px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg)
    scale3d(0, 0, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg)
    scale3d(0, 0, 1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
}

figure.effect-hera p {
  width: 100px;
  text-transform: none;
  font-size: 121%;
  line-height: 2;
}

figure.effect-hera p a {
  color: #fff;
}

figure.effect-hera p a:hover,
figure.effect-hera p a:focus {
  opacity: 0.6;
}

figure.effect-hera p a i {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-hera p a:first-child i {
  -webkit-transform: translate3d(-60px, -60px, 0);
  transform: translate3d(-60px, -60px, 0);
}

figure.effect-hera p a:nth-child(2) i {
  -webkit-transform: translate3d(60px, -60px, 0);
  transform: translate3d(60px, -60px, 0);
}

figure.effect-hera p a:nth-child(3) i {
  -webkit-transform: translate3d(-60px, 60px, 0);
  transform: translate3d(-60px, 60px, 0);
}

figure.effect-hera p a:nth-child(4) i {
  -webkit-transform: translate3d(60px, 60px, 0);
  transform: translate3d(60px, 60px, 0);
}

figure.effect-hera:hover figcaption::before {
  opacity: 1;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg)
    scale3d(1, 1, 1);
  transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 1, -45deg)
    scale3d(1, 1, 1);
}

figure.effect-hera:hover h2 {
  opacity: 0;
  -webkit-transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(0.8, 0.8, 1);
}

figure.effect-hera:hover p i:empty {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(
    0,
    0,
    0
  ); /* just because it's stronger than nth-child */
  opacity: 1;
}

/*-----------------*/
/***** Winston *****/
/*-----------------*/

figure.effect-winston {
  background: #162633;
  text-align: left;
}

figure.effect-winston img {
  -webkit-transition: opacity 0.45s;
  transition: opacity 0.45s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-winston figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url(../img/triangle.svg) no-repeat center center; */
  background-size: 100% 100%;
  content: '';
  -webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
  transition: opacity 0.45s, transform 0.45s;
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
  transform: rotate3d(0, 0, 1, 45deg);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

figure.effect-winston h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

figure.effect-winston p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 7% 0;
}

figure.effect-winston a {
  margin: 0 10px;
  color: #5d504f;
  font-size: 170%;
}

figure.effect-winston a:hover,
figure.effect-winston a:focus {
  color: #cc6055;
}

figure.effect-winston p a i {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
}

figure.effect-winston:hover img {
  opacity: 0.6;
}

figure.effect-winston:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-winston:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: rotate3d(0, 0, 1, 20deg);
  transform: rotate3d(0, 0, 1, 20deg);
}

figure.effect-winston:hover p i {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-winston:hover p a:nth-child(3) i {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-winston:hover p a:nth-child(2) i {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

figure.effect-winston:hover p a:first-child i {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

/*-----------------*/
/***** Selena *****/
/*-----------------*/

figure.effect-selena {
  background: #fff;
}

figure.effect-selena img {
  opacity: 0.95;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-selena:hover img {
  -webkit-transform: scale3d(0.95, 0.95, 1);
  transform: scale3d(0.95, 0.95, 1);
}

figure.effect-selena h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 20px, 0);
}

figure.effect-selena p {
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
  transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

figure.effect-selena:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-selena:hover p {
  opacity: 1;
  -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 0);
  transform: perspective(1000px) rotate3d(1, 0, 0, 0);
}

/*-----------------*/
/***** Terry *****/
/*-----------------*/

figure.effect-terry {
  background: #34495e;
}

figure.effect-terry figcaption {
  padding: 1em;
}

figure.effect-terry figcaption::before,
figure.effect-terry figcaption::after {
  position: absolute;
  width: 200%;
  height: 200%;
  border-style: solid;
  border-color: #101010;
  content: '';
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-terry figcaption::before {
  right: 0;
  bottom: 0;
  border-width: 0 70px 60px 0;
  -webkit-transform: translate3d(70px, 60px, 0);
  transform: translate3d(70px, 60px, 0);
}

figure.effect-terry figcaption::after {
  top: 0;
  left: 0;
  border-width: 15px 0 0 15px;
  -webkit-transform: translate3d(-15px, -15px, 0);
  transform: translate3d(-15px, -15px, 0);
}

figure.effect-terry img,
figure.effect-terry p a {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-terry img {
  opacity: 0.85;
}

figure.effect-terry h2 {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.4em 10px;
  width: 50%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

@media screen and (max-width: 920px) {
  figure.effect-terry h2 {
    padding: 0.75em 10px;
    font-size: 120%;
  }
}

figure.effect-terry p {
  float: right;
  clear: both;
  text-align: left;
  text-transform: none;
  font-size: 111%;
}

figure.effect-terry p a {
  display: block;
  margin-bottom: 1em;
  color: #fff;
  opacity: 0;
  -webkit-transform: translate3d(90px, 0, 0);
  transform: translate3d(90px, 0, 0);
}

figure.effect-terry p a:hover,
figure.effect-terry p a:focus {
  color: #f3cf3f;
}

figure.effect-terry:hover figcaption::before,
figure.effect-terry:hover figcaption::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-terry:hover img {
  opacity: 0.6;
}

figure.effect-terry:hover h2,
figure.effect-terry:hover p a {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-terry:hover p a {
  opacity: 1;
}

figure.effect-terry:hover p a:first-child {
  -webkit-transition-delay: 0.025s;
  transition-delay: 0.025s;
}

figure.effect-terry:hover p a:nth-child(2) {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

figure.effect-terry:hover p a:nth-child(3) {
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

figure.effect-terry:hover p a:nth-child(4) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

/*-----------------*/
/***** Phoebe *****/
/*-----------------*/

figure.effect-phoebe {
  background: #675983;
}

figure.effect-phoebe img {
  opacity: 0.85;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-phoebe:hover img {
  opacity: 0.6;
}

figure.effect-phoebe figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: url(../img/triangle2.svg) no-repeat center center; */
  background-size: 100% 100%;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(5, 2.5, 1);
  transform: scale3d(5, 2.5, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-phoebe:hover figcaption::before {
  opacity: 0.6;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

figure.effect-phoebe h2 {
  margin-top: 1em;
  -webkit-transition: transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
}

figure.effect-phoebe:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-phoebe p a {
  color: #fff;
  font-size: 140%;
  opacity: 0;
  position: relative;
  display: inline-block;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-phoebe p a:first-child {
  -webkit-transform: translate3d(-60px, -60px, 0);
  transform: translate3d(-60px, -60px, 0);
}

figure.effect-phoebe p a:nth-child(2) {
  -webkit-transform: translate3d(0, 60px, 0);
  transform: translate3d(0, 60px, 0);
}

figure.effect-phoebe p a:nth-child(3) {
  -webkit-transform: translate3d(60px, -60px, 0);
  transform: translate3d(60px, -60px, 0);
}

figure.effect-phoebe:hover p a {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*-----------------*/
/***** Apollo *****/
/*-----------------*/

figure.effect-apollo {
  background: #3498db;
}

figure.effect-apollo img {
  opacity: 0.95;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1);
}

figure.effect-apollo figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  content: '';
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
    translate3d(0, -100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
    translate3d(0, -100%, 0);
}

figure.effect-apollo p {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 3em;
  padding: 0 1em;
  max-width: 150px;
  border-right: 4px solid #fff;
  text-align: right;
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-apollo h2 {
  text-align: left;
}

figure.effect-apollo:hover img {
  opacity: 0.6;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

figure.effect-apollo:hover figcaption::before {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
    translate3d(0, 100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg)
    translate3d(0, 100%, 0);
}

figure.effect-apollo:hover p {
  opacity: 1;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

/*-----------------*/
/***** Kira *****/
/*-----------------*/

figure.effect-kira {
  background: #fff;
  text-align: left;
}

figure.effect-kira img {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-kira figcaption {
  z-index: 1;
}

figure.effect-kira p {
  padding: 2.25em 0.5em;
  font-weight: 600;
  font-size: 100%;
  line-height: 1.5;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

figure.effect-kira p a {
  margin: 0 0.5em;
  color: #101010;
}

figure.effect-kira p a:hover,
figure.effect-kira p a:focus {
  opacity: 0.6;
}

figure.effect-kira figcaption::before {
  position: absolute;
  top: 0;
  right: 2em;
  left: 2em;
  z-index: -1;
  height: 3.5em;
  background: #fff;
  content: '';
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(0, 4em, 0) scale3d(1, 0.023, 1);
  transform: translate3d(0, 4em, 0) scale3d(1, 0.023, 1);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

figure.effect-kira:hover img {
  opacity: 0.5;
}

figure.effect-kira:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-kira:hover figcaption::before {
  opacity: 0.7;
  -webkit-transform: translate3d(0, 5em, 0) scale3d(1, 1, 1);
  transform: translate3d(0, 5em, 0) scale3d(1, 1, 1);
}

/*-----------------*/
/***** Steve *****/
/*-----------------*/

figure.effect-steve {
  z-index: auto;
  overflow: visible;
  background: #000;
}

figure.effect-steve:before,
figure.effect-steve h2:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #000;
  content: '';
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-steve:before {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.8);
  opacity: 0;
}

figure.effect-steve figcaption {
  z-index: 1;
}

figure.effect-steve img {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: perspective(1000px) translate3d(0, 0, 0);
  transform: perspective(1000px) translate3d(0, 0, 0);
}

figure.effect-steve h2,
figure.effect-steve p {
  background: #fff;
  color: #2d434e;
}

figure.effect-steve h2 {
  position: relative;
  margin-top: 2em;
  padding: 0.25em;
}

figure.effect-steve h2:before {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
}

figure.effect-steve p {
  margin-top: 1em;
  padding: 0.5em;
  font-weight: 800;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(0.9, 0.9, 1);
  transform: scale3d(0.9, 0.9, 1);
}

figure.effect-steve:hover:before {
  opacity: 1;
}

figure.effect-steve:hover img {
  -webkit-transform: perspective(1000px) translate3d(0, 0, 21px);
  transform: perspective(1000px) translate3d(0, 0, 21px);
}

figure.effect-steve:hover h2:before {
  opacity: 0;
}

figure.effect-steve:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

/*-----------------*/
/***** Moses *****/
/*-----------------*/

/*figure.effect-moses {
	background: -webkit-linear-gradient(-45deg, #EC65B7 0%,#05E0D8 100%);
	background: linear-gradient(-45deg, #EC65B7 0%,#05E0D8 100%);
}*/

figure.effect-moses img {
  opacity: 0.85;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-moses h2,
figure.effect-moses p {
  padding: 20px;
  width: 50%;
  height: 50%;
  border: 2px solid #fff;
}

figure.effect-moses h2 {
  padding: 60px 10px;
  width: 50%;
  height: 50%;
  color: #ffffff;
  font-size: 20px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0);
}

figure.effect-moses p {
  float: right;
  padding: 15px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
@media only screen and (max-width: 600px) {
  figure.effect-moses p {
    opacity: 1 !important;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
    position: relative !important;
    left: -1px !important;
    top: -1px !important;
  }
  figure.effect-moses h2 {
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
  }
  figure.effect-moses img {
    opacity: 0.6 !important;
  }
}

figure.effect-moses:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-moses:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  left: -1px;
  top: -1px;
}

figure.effect-moses:hover img {
  opacity: 0.6;
}

/*---------------*/
/***** Jazz *****/
/*---------------*/

figure.effect-jazz {
  background: -webkit-linear-gradient(-45deg, #f3cf3f 0%, #f33f58 100%);
  background: linear-gradient(-45deg, #f3cf3f 0%, #f33f58 100%);
}

figure.effect-jazz img {
  opacity: 0.9;
}

figure.effect-jazz figcaption::after,
figure.effect-jazz img,
figure.effect-jazz p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-jazz figcaption::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  content: '';
  opacity: 0;
  -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
  transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-jazz h2,
figure.effect-jazz p {
  opacity: 1;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
}

figure.effect-jazz h2 {
  padding-top: 26%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-jazz p {
  padding: 0.5em 2em;
  text-transform: none;
  font-size: 0.85em;
  opacity: 0;
}

figure.effect-jazz:hover img {
  opacity: 0.7;
  -webkit-transform: scale3d(1.05, 1.05, 1);
  transform: scale3d(1.05, 1.05, 1);
}

figure.effect-jazz:hover figcaption::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
  transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
}

figure.effect-jazz:hover h2,
figure.effect-jazz:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

/*---------------*/
/***** Ming *****/
/*---------------*/

figure.effect-ming {
  background: #030c17;
}

figure.effect-ming img {
  opacity: 0.9;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

figure.effect-ming figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 2px solid #fff;
  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale3d(1.4, 1.4, 1);
  transform: scale3d(1.4, 1.4, 1);
}

figure.effect-ming h2 {
  margin: 20% 0 10px 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

figure.effect-ming p {
  padding: 1em;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

figure.effect-ming:hover h2 {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

figure.effect-ming:hover figcaption::before,
figure.effect-ming:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

figure.effect-ming:hover figcaption {
  background-color: rgba(58, 52, 42, 0);
}

figure.effect-ming:hover img {
  opacity: 0.4;
}

/*---------------*/
/***** Lexi *****/
/*---------------*/

figure.effect-lexi {
  background: -webkit-linear-gradient(-45deg, #000 0%, #fff 100%);
  background: linear-gradient(-45deg, #000 0%, #fff 100%);
}

figure.effect-lexi img {
  margin: -10px 0 0 -10px;
  max-width: none;
  width: -webkit-calc(100% + 10px);
  width: calc(100% + 10px);
  opacity: 0.9;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-lexi figcaption::before,
figure.effect-lexi p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-lexi figcaption::before {
  position: absolute;
  right: -100px;
  bottom: -100px;
  width: 300px;
  height: 300px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 900px rgba(255, 255, 255, 0.2);
  content: '';
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 1);
  transform: scale3d(0.5, 0.5, 1);
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

figure.effect-lexi:hover img {
  opacity: 0.6;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-lexi h2 {
  text-align: left;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(5px, 5px, 0);
  transform: translate3d(5px, 5px, 0);
}

figure.effect-lexi p {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 1.5em 1.5em 0;
  width: 140px;
  text-align: right;
  opacity: 0;
  -webkit-transform: translate3d(20px, 20px, 0);
  transform: translate3d(20px, 20px, 0);
}

figure.effect-lexi:hover figcaption::before {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

figure.effect-lexi:hover h2,
figure.effect-lexi:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

/*---------------*/
/***** Duke *****/
/*---------------*/

figure.effect-duke {
  background: -webkit-linear-gradient(-45deg, #34495e 0%, #cc6055 100%);
  background: linear-gradient(-45deg, #34495e 0%, #cc6055 100%);
}

figure.effect-duke img,
figure.effect-duke p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-duke:hover img {
  opacity: 0.1;
  -webkit-transform: scale3d(2, 2, 1);
  transform: scale3d(2, 2, 1);
}

figure.effect-duke h2 {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

figure.effect-duke p {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 20px;
  padding: 30px;
  border: 2px solid #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transform-origin: 50% -100%;
  transform-origin: 50% -100%;
}

figure.effect-duke:hover h2,
figure.effect-duke:hover p {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

/* Media queries */
@media screen and (max-width: 50em) {
  .content {
    padding: 0 10px;
    text-align: center;
  }
  .grid figure {
    display: inline-block;
    float: none;
    margin: 10px auto;
    width: 100%;
  }
}
